.node-edit-dialog .ant-btn-primary {
    background-color: #3362FF;
}

#network {
    margin-top: 2rem;
    margin-left: 10vw;
    margin-right: 30vw;
    display: block;
    height: calc(100vh - 2rem);
    background-color: #F5F5F5;
    border: 2px solid black;
    border-radius: 10px;
}